<template>
    
    <div class="property_item heading_space">
        
        <div class="image">
            <div class="img-responsive webkit-animation webkit-100per-250"></div>
            <div class="price clearfix"> 
                <span class="tag pull-right webkit-animation webkit-50per-100"></span>
            </div>
            <span class="tag_t webkit-animation webkit-25per-35"></span> 
            <span class="tag_l webkit-animation webkit-25per-35"></span>
        </div>
            
        <div class="proerty_content">
            <div class="proerty_text">
                <h3 class="captlize">
                    <div class="webkit-animation webkit-50per-30 mb-5"></div>
                </h3>
                <p class="webkit-animation webkit-100per-30"></p>
            </div>

            <div class="property_meta transparent"> 
                <span><span class="webkit-animation webkit-40-20 bottom15"></span></span> 
                <span><span class="webkit-animation webkit-40-20 bottom15"></span></span> 
                <span><span class="webkit-animation webkit-40-20 bottom15"></span></span> 
            </div>

            <div class="property_meta transparent bottom30">
                <span><span class="webkit-animation webkit-40-20"></span></span> 
                <span><span class="webkit-animation webkit-40-20"></span></span> 
                <span><span class="webkit-animation webkit-40-20"></span></span> 
            </div>

            <div class="favroute clearfix">
                <p class="pull-md-left webkit-animation webkit-50-30"></p>
            </div>
        </div>

    </div>

</template>

<script>    
export default {
    name: 'PropertyLoading',
    props: [],
    data() {
        return {
            //
        }
    }
}
</script>